import React, {useState, useEffect} from 'react';
import {useParams} from "react-router-dom";
import useHyphen from 'react-use-hyphen';
import {Helmet} from "react-helmet";
import Vimeo from '@u-wave/react-vimeo';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
const Vimeoblock = _ => {
    let params = useParams();
    const _id = params.id;
    const [data, setData] = useState({});
    const [filterByID, setfilterByID] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const {Hyphen} = useHyphen();
    return <><div className={'organization clearfix'}>
        <Helmet>
            <title>{'FTH.tv – Freie Theater Hannover'}</title>
        </Helmet>
        <section className={'mt-0 pt-0 bg-black'}>
            <div className={'container pb-5 text-white'}>
                <div className={'row'}>
                    <div className={'col-12 pt-5'}>
                        <h1 className={'fz-140 text-uppercase mt-5 hyphenation'}>FTH.tv</h1>
                    </div>
                </div>
            </div>
            <div className={'container-fluid border-top border-3  pb-3 pt-3'}>
                <div className={'row'}>
                    <div className={'col-lg-6 border-end border-bottom border-3'}>
                        <LiteYouTubeEmbed
                            id="5EfnoRnZocw"
                            poster="maxresdefault"
                            title=" Webserie Was für ein Theater. - Episode 1 Agentur für Weltverbesserungspläne "
                        />
                        <div className={'clearfix'}></div>
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3  pb-3 pt-3'}>
                        <LiteYouTubeEmbed
                            id="vkNuP1PrPiI"
                            poster="maxresdefault"
                            title="Webserie Was für ein Theater. - Episode 2 Landerer&Company "
                        />
                        <div className={'clearfix'}></div>
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3  pb-3 pt-3'}>
                        <LiteYouTubeEmbed
                            id="vkNuP1PrPiI"
                            poster="maxresdefault"
                            title="Webserie Was für ein Theater. - Episode 3 Figurentheater Neumond"
                        />
                        <div className={'clearfix'}></div>
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3  pb-3 pt-3'}>
                        <LiteYouTubeEmbed
                            id="tkzZKi_dDj8"
                            poster="maxresdefault"
                            title="Webserie Was für ein Theater. - Episode 4 Monica Garcia Vicente"
                        />
                        <div className={'clearfix'}></div>
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3  pb-3 pt-3'}>
                        <LiteYouTubeEmbed
                            id="AZsDf0GgetI"
                            poster="maxresdefault"
                            title="Webserie Was für ein Theater. - Episode 5 Commedia Futura"
                        />
                        <div className={'clearfix'}></div>
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3  pb-3 pt-3'}>
                        <LiteYouTubeEmbed
                            id="lw2fZrjRQTs"
                            poster="maxresdefault"
                            title="Webserie Was für ein Theater. - Episode 6 Fensterzurstadt"
                        />
                        <div className={'clearfix'}></div>
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3  pb-3 pt-3'}>
                        <LiteYouTubeEmbed
                            id="9NsTWjUY_j8"
                            poster="maxresdefault"
                            title="Webserie Was für ein Theater. - Episode 7 Quartiertheater"
                        />
                        <div className={'clearfix'}></div>
                    </div>
                    <div className={'col-lg-6 border-end border-bottom border-3  pb-3 pt-3'}>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div className={'clearfix float-none'}></div>
    </>

};

export default Vimeoblock;

