import MailchimpSubscribe from "react-mailchimp-subscribe";
const url = "https://freie-theater-hannover.us12.list-manage.com/subscribe/post?u=1e6e9f72f37776a494df93e4c&amp;id=1542831e43&amp;f_id=00dde7e0f0";
const SimpleForm = () => <MailchimpSubscribe url={url}/>
const CustomForm = () => (
    <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
            <>
                <SimpleForm onSubmitted={formData => subscribe(formData)} />
                {status === "sending" && <div style={{ color: "black" }}>sending...</div>}
                {status === "error" && <div style={{ color: "black" }} dangerouslySetInnerHTML={{__html: message}}/>}
                {status === "success" && <div style={{ color: "black" }}>Subscribed !</div>}
            </>
        )}
    />
)
function Mailchimp() {
    return (
        <div className={'col-12 text-center pt-4 pb-5 newsletter-form'}>
            <p className={'text-uppercase text-dark pb-3'}><span className={'h3'}>Abonniere den Newsletter</span><small><br />der Freien Theater Hannover</small></p>
            <CustomForm/>
        </div>
    );
}

export default Mailchimp;