import React, {Component} from 'react';
/*import {getCollectionUrl} from "../utils";*/
import {NavLink} from "react-router-dom";
/*import {Image} from "@wbe/lazy-image";*/
import {getSingeleton} from "../utils";
import {Helmet} from "react-helmet";

class Statementmsg extends Component {

    constructor(props) {
        super(props);
        this.state = {
            eventlist: [],
            allevents: [],
            highlights: [],
            image: null,
            total_chunks: 0,
            current_chunks: 0,
            chunkSize: 20,
            loading: true,
            isPublished: false,
            organizationType: props.organizationType,
        }
    }

    getData() {
        fetch(getSingeleton('home'), {
            mode: 'cors',
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                    populate: 1,
                }
            ),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    eventlist: data,
                    loading: false,
                    isPublished: data.published
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const isPublished = this.state.isPublished;
        let output;
        if (isPublished) {
            output = <section className={'container-fluid bg-secondary'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <h1 className={'h4 text-uppercase mt-3 hyphenationhyphenation'}>{this.state.eventlist.name}</h1>
                                </div>
                                <div className={'col-lg-9 pb-3 mb-3'}>
                                    <p>{this.state.eventlist.intro}</p>
                                    <NavLink
                                        className={`btn btn-dark rounded-pill text-center text-uppercase`}
                                        to={'/statement'}
                                        title={this.state.eventlist.name}
                                    ><b>Lese mehr</b>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>;
        }else {
            output = <></>;
        }

        return (
            <>{output}</>
        );
    }
}

export default Statementmsg;

